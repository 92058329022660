import { Grommet, Box, Heading, Button, CheckBox, Tab, Tabs } from 'grommet';
import { AddCircle } from 'grommet-icons';

import theme from '../../theme';
import { useColorScheme } from '../../hooks';
import React from 'react';

const Components = () => {
  const colorScheme = useColorScheme(theme.defaultMode);

  return (
    <Grommet full theme={theme} themeMode={colorScheme ? 'light' : 'dark'} background="background-back">
      <Box pad={{ horizontal: 'none', vertical: 'medium' }} width={{ min: 'medium', max: 'large' }} margin={{ horizontal: 'auto', top: 'large' }} background="background-front">
        <Box align="center" justify="center">
          <Heading>Buttons</Heading>
        </Box>
        <Box align="center" justify="center" direction="row" gap="medium">
          <Button label="Button" />
          <Button label="primary" primary />
          <Button label="disabled" disabled />
          <Button label="large" size="large" />
          <Button label="small" size="small" />
        </Box>

        <Box align="center" justify="center">
          <Heading margin={{ top: 'large' }}>Checkbox</Heading>
        </Box>
        <Box align="center" justify="center" direction="row" pad="xsmall" gap="medium">
          <CheckBox label="CheckBox" />
          <CheckBox label="disabled" disabled />
          <CheckBox label="checked" checked />
        </Box>

        <Box align="center" justify="center">
          <Heading margin={{ top: 'large' }}>Toggle</Heading>
        </Box>
        <Box align="center" justify="center" direction="row" pad="xsmall" gap="medium">
          <CheckBox label="Toggle" toggle />
          <CheckBox label="disabled" disabled toggle />
          <CheckBox label="checked" checked toggle />
        </Box>

        <Box align="center" justify="center">
          <Heading margin={{ top: 'large' }}>Tabs</Heading>
        </Box>
        <Box align="center" justify="center" direction="row" pad="xsmall" gap="medium">
          <Tabs justify="start">
            <Tab title="tab" />
            <Tab title="disabled" disabled plain={false} />
            <Tab title="tab" />
            <Tab title="icon tab" icon={<AddCircle />} />
          </Tabs>
        </Box>
      </Box>
    </Grommet>
  );
};

export default Components;
